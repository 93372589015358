import { faTools } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Seo from "../components/seo"

const Remodel = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="リフォーム" description="株式会社ファーストのリフォーム" />
      <div className="container">
        <div
          style={{
            textAlign: "center",
            fontSize: "2rem",
            color: "gray",
            margin: "10px 0px",
          }}
        >
          <FontAwesomeIcon icon={faTools} />
        </div>
        <h3 className="title is-4" style={{ color: "gray" }}>
          リフォーム
        </h3>
        <section className="section">
          <h4 className="title is-4">コスト重視のリフォーム</h4>
          <p>
            「古くなった住宅を修繕したい」「購入した物件を住みやすく改修したい」など、リフォームやリノベーションをお手伝いいたします。
          </p>
          <p className="block">
            「予算をできるだけ抑えたい」「一部だけリフォームしたい」といったコスト重視のご要望も、当社であれば柔軟に対応が可能です。
          </p>
          <div className="columns is-centered">
            <div className="column is-half">
              <StaticImage
                src="../images/flows/flow-remodel.drawio.png"
                style={{ margin: "0 auto" }}
                placeholder="none"
                quality={90}
                alt=""
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>リフォーム相談</span>
                  <ul>
                    <li>お客様のご要望にをお聞き致します</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>現地調査</span>
                  <ul>
                    <li>
                      物件を調査し、ご要望と物件の状況を照らし合わせてプランを提案します
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>契約・着工</span>
                  <ul>
                    <li>工事を実施します</li>
                    <li>合わせて、近隣の方へのご説明も行います</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>引き渡し</span>
                  <ul>
                    <li>工事完了後、物件を引き渡します</li>
                    <li>引き渡し後の疑問点や相談もお受けいたします</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <p>
            他社で見積もりしたが予算オーバーだった、他社では断られてしまった場合でもお気軽にご相談下さい。
          </p>
          <Link
            to="/contact"
            className="button is-dark is-fullwidth my-4 is-size-5"
            style={{ height: "4em" }}
          >
            お問い合わせはこちら　＞
          </Link>
        </section>
      </div>
    </Layout>
  )
}

export default Remodel
